import { Module } from "vuex";
import IUser from "@/types/user";
import { ILoginRequest, ILoginResponse } from "@/types/login";
import { getUser, login } from "@/api/api";
import Vue from "vue";
import setGlobalHeader from "@/utils/set-global-header";
import { IRootState } from "@/types/root-store";

function getDefaultState(): IRootState {
  return {
    token: "",
    user: null,
  };
}

const rootModule: Module<any, any> = {
  state: getDefaultState(),
  mutations: {
    SET_USER(state, user: IUser) {
      state.user = user;
    },
    SET_TOKEN(state, payload: ILoginResponse) {
      state.token = payload.token;
    },
  },
  getters: {
    token: (state) => state.token,
    userPermissions: (state) => {
      if (!state.user) {
        return null;
      }
      const { manageCourses, manageUsers, readAnalytics } = state.user;
      return {
        manageCourses,
        manageUsers,
        readAnalytics,
      };
    },
    userName: (state) => state.user && state.user.fullName,
    userId: (state) => state.user && state.user.id,
    canManageCourses: (state) => state.user && state.user.manageCourses,
    canManageUsers: (state) => state.user && state.user.manageUsers,
    canReadAnalytics: (state) => state.user && state.user.readAnalytics,
    userEmail: (state) => state.user && state.user.email,
  },
  actions: {
    async login({ commit, dispatch }, payload: ILoginRequest) {
      const response = await login(payload);

      Vue.$cookies.set("jwt", response.token);
      setGlobalHeader(response.token);
      commit("SET_TOKEN", response);
      dispatch("getUser");
    },
    logout({ commit }) {
      Vue.$cookies.remove("jwt");
      commit("SET_TOKEN", { token: "" });
      commit("SET_USER", null);
    },
    async getUser({ commit }) {
      let res = false;
      try {
        const res = await getUser();
        commit("SET_USER", res);
      } catch (e) {
        res = false;
      }

      return res;
    },
  },
};

export default rootModule;
