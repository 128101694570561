import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContent,{staticClass:"login"},[_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"sm8":"","md4":""}},[_c(VCard,{staticClass:"elevation-12"},[_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"rules":[_vm.requiredRule],"name":"username","label":"Имя пользователя","type":"text","required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"rules":[_vm.requiredRule],"id":"password","name":"password","label":"Пароль","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"login__submit",attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.onButtonClick}},[_vm._v("Войти")])],1),_c(VExpandTransition,[(_vm.loginError)?_c(VCardText,{staticClass:"login__error"},[_vm._v(" Неверный логин или пароль ")]):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }