import httpClient from "./http-client";
import { ILoginRequest, ILoginResponse } from "@/types/login";
import IUser from "@/types/user";
import IEditUserPermissions from "@/types/edit-user-permissions";
import ISuccess from "@/types/success";
import IUserCreate from "@/types/user-create";
import IId from "@/types/id";
import IChangePassword from "@/types/change-password";
import IEditLessonPermissions from "@/types/edit-lesson-permissions";
import ICourse from "@/types/course";
import ILesson from "@/types/lesson";
import { ICreateEvent, IEvent } from "@/types/create-event";
import IUserResult, { ILessonResponse } from "@/types/user-result";
import IAllUsersResult from "@/types/all-users-result";
import { IAllLessonsPermissions } from "@/types/lesson-permissions";

const LOGIN = "/login";
const GET_USER = "/getUser";
const GET_ALL_USERS = "/getUsers";
const EDIT_PERMISSIONS = "/editPermissions";
const CREATE_USER = "/createUser";
const CHANGE_PASSWORD = "/changePassword";
const EDIT_LESSON_PERMISSIONS = "/editLessonPermissions";
const GET_ALL_COURSES = "/getCourses";
const CREATE_COURSE = "/createCourse";
const UPLOAD_FILE = "/uploadFile";
const EDIT_COURSE = "/editCourse";
const GET_COURSE = "/getCourse";
const DELETE_COURSE = "/deleteCourse";
const CRETE_LESSON = "/createLesson";
const GET_ALL_LESSONS = "/getLessons";
const GET_LESSON = "/getLesson";
const DELETE_LESSON = "/deleteLesson";
const EDIT_LESSON = "/editLesson";
const ADD_LESSON_EVENT = "/addLessonEvent";
const GET_LESSON_EVENT = "/getLessonEvents";
const DELETE_LESSON_EVENT = "/deleteLessonEvent";
const EDIT_EVENT_LESSON = "/editLessonEvent";
const GET_NEXT_LESSON = "/getNextLesson";
const GET_USER_RESULTS = "/getUserResults";
const SUBMIT_LESSON_RESULTS = "/submitLessonResults";
const GET_ALL_USERS_RESULTS = "/getUsersResults";
const GET_LESSON_RESULTS = "/getLessonResults";
const DELETE_USER = "/deleteUser";
const GET_AVAILABLE_PERMISSIONS = "/getAvailablePermissions";
const SET_EVENT_TIME = "/setEventTime"

async function login(data: ILoginRequest): Promise<ILoginResponse> {
  return (await httpClient.post(LOGIN, data)).data;
}

async function getUser(): Promise<IUser> {
  return (await httpClient.get(GET_USER)).data;
}

async function getAllUsers(): Promise<IUser[]> {
  return (await httpClient.get(GET_ALL_USERS)).data.Users;
}

async function editPermissions(data: IEditUserPermissions): Promise<ISuccess> {
  return (await httpClient.post(EDIT_PERMISSIONS, data)).data;
}

async function createUser(data: IUserCreate): Promise<IId> {
  return (await httpClient.post(CREATE_USER, data)).data;
}

async function changePassword(data: IChangePassword): Promise<ISuccess> {
  return (await httpClient.post(CHANGE_PASSWORD, data)).data;
}

async function editLessonPermissions(
  data: IEditLessonPermissions
): Promise<ISuccess> {
  return (await httpClient.post(EDIT_LESSON_PERMISSIONS, data)).data;
}

async function getAllCourses(): Promise<ICourse[]> {
  return (await httpClient.get(GET_ALL_COURSES)).data.courses;
}

async function createCourse(data: ICourse): Promise<any> {
  return await httpClient.post(CREATE_COURSE, data);
}

async function uploadFile(data: File, onUploadProgress: (progress: number) => void): Promise<string> {
  return (
    await httpClient.post(
      UPLOAD_FILE,
      { upload: data },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onUploadProgress(progress);
        },
      }
    )
  ).data.fileName;
}

async function editCourse(course: ICourse): Promise<ISuccess> {
  return await httpClient.post(EDIT_COURSE, course);
}

async function getCourse(courseID: string): Promise<ICourse> {
  return (await httpClient.get(`${GET_COURSE}?courseID=${courseID}`)).data;
}

async function deleteCourse(courseID: number): Promise<ISuccess> {
  return (await httpClient.post(DELETE_COURSE, { courseID })).data;
}

async function createLesson(lesson: ILesson): Promise<{ LessonID: number }> {
  return (await httpClient.post(CRETE_LESSON, lesson)).data;
}

async function getAllLessons(courseID: string): Promise<ILesson[]> {
  return (await httpClient.get(`${GET_ALL_LESSONS}?courseID=${courseID}`)).data
    .lessons;
}

async function getLesson(lessonID: string): Promise<ILesson> {
  return (await httpClient.get(`${GET_LESSON}?lessonID=${lessonID}`)).data;
}

async function deleteLesson(lessonID: number): Promise<ISuccess> {
  return (await httpClient.post(DELETE_LESSON, { lessonID })).data;
}

async function editLesson(lesson: ILesson): Promise<ISuccess> {
  return (await httpClient.post(EDIT_LESSON, lesson)).data;
}

async function addEventLesson(
  event: ICreateEvent
): Promise<{ eventID: number }> {
  return (await httpClient.post(ADD_LESSON_EVENT, event)).data;
}

async function getLessonEvent(lessonID: string): Promise<IEvent[]> {
  return (await httpClient.get(`${GET_LESSON_EVENT}?lessonID=${lessonID}`)).data
    .events;
}

async function deleteLessonEvent(eventID: number): Promise<ISuccess> {
  return await httpClient.post(DELETE_LESSON_EVENT, { eventID });
}

async function editLessonEvent(event: IEvent): Promise<ISuccess> {
  return await httpClient.post(EDIT_EVENT_LESSON, event);
}

async function setEventTime(eventID: number, time: number): Promise<ISuccess> {
  console.log(eventID, time)
  return await httpClient.post(SET_EVENT_TIME, {eventID, time})
}

async function getNextLesson(lessonID?: number): Promise<ILesson> {
  const url = lessonID
    ? `${GET_NEXT_LESSON}?lessonID=${lessonID}`
    : GET_NEXT_LESSON;

  return (await httpClient.get(url)).data.lesson;
}

async function getUserResults(userId: number): Promise<IUserResult> {
  return (await httpClient.get(`${GET_USER_RESULTS}?userID=${userId}`)).data;
}

async function submitLessonResults(lessonID: number): Promise<any> {
  return (await httpClient.post(SUBMIT_LESSON_RESULTS, { lessonID })).data;
}

async function getAllUsersResults(): Promise<IAllUsersResult[]> {
  return (await httpClient.get(GET_ALL_USERS_RESULTS)).data.results;
}

async function getLessonResults(
  lessonID: number,
  userID: number
): Promise<ILessonResponse[]> {
  const url = `${GET_LESSON_RESULTS}?lessonID=${lessonID}&userID=${userID}`;
  return (await httpClient.get(url)).data.lessonResults;
}

async function deleteUser(userID: number): Promise<ISuccess> {
  return await httpClient.post(DELETE_USER, { userID });
}

async function getAvailablePermissions(): Promise<IAllLessonsPermissions[]> {
  return (await httpClient.get(GET_AVAILABLE_PERMISSIONS)).data.permissions;
}

export {
  login,
  getUser,
  getAllUsers,
  editPermissions,
  createUser,
  changePassword,
  editLessonPermissions,
  getAllCourses,
  createCourse,
  getCourse,
  editCourse,
  uploadFile,
  deleteCourse,
  createLesson,
  getAllLessons,
  getLesson,
  deleteLesson,
  editLesson,
  addEventLesson,
  getLessonEvent,
  deleteLessonEvent,
  editLessonEvent,
  getNextLesson,
  getUserResults,
  submitLessonResults,
  getAllUsersResults,
  getLessonResults,
  deleteUser,
  getAvailablePermissions,
  setEventTime
};
