import {
  changePassword,
  createUser,
  editLessonPermissions,
  editPermissions,
  getAllUsers,
  getAvailablePermissions,
} from "@/api/api";
import IUser from "@/types/user";
import { Module } from "vuex";
import IUserCreate from "@/types/user-create";
import IEditUserPermissions from "@/types/edit-user-permissions";
import IChangePassword from "@/types/change-password";
import { IAllLessonsPermissions } from "@/types/lesson-permissions";
import IEditLessonPermissions from "@/types/edit-lesson-permissions";

function getDefaultState(): {
  users: IUser[];
  lessonPermissions: IAllLessonsPermissions[];
} {
  return {
    users: [],
    lessonPermissions: [],
  };
}

const usersState = getDefaultState();
const usersModule: Module<any, any> = {
  namespaced: true,
  state: usersState,
  actions: {
    async updateUserLessonPermissions(
      { commit },
      payload: IEditLessonPermissions
    ) {
      let error = "";

      try {
        await editLessonPermissions(payload);
        commit("SET_USER_LESSON_PERMISSIONS", payload);
        error = "";
      } catch (e) {
        console.error(e);
        error =
          e.response?.data?.error || "Что-то пошло не так. Попробуйте еще раз.";
      }

      return error;
    },
    async getAllLessonPermissions({ commit }): Promise<void> {
      try {
        const res = await getAvailablePermissions();
        commit("SET_ALL_LESSON_PERMISSIONS", res);
      } catch (e) {
        console.error(e);
      }
    },
    async changePassword(
      { commit },
      payload: IChangePassword
    ): Promise<string> {
      let error = "";
      try {
        await changePassword(payload);
        error = "";
      } catch (e) {
        error =
          e.response?.data?.error || "Что-то пошло не так. Попробуйте еще раз.";
      }

      return error;
    },
    async editPermissions(
      { commit },
      payload: IEditUserPermissions
    ): Promise<string> {
      let error = "";
      try {
        await editPermissions(payload);
        commit("EDIT_PERMISSIONS", payload);
        error = "";
      } catch (e) {
        error =
          e.response?.data?.error || "Что-то пошло не так. Попробуйте еще раз.";
      }

      return error;
    },
    async getAllUsers({ commit }): Promise<string> {
      let error = "";
      try {
        const res = await getAllUsers();
        commit("SET_ALL_USERS", res);
        error = "";
      } catch (e) {
        error =
          e.response?.data?.error || "Что-то пошло не так. Попробуйте еще раз.";
      }

      return error;
    },
    async createUser({ commit }, payload: IUserCreate): Promise<string> {
      let error = "";

      try {
        const { userID } = await createUser(payload);
        const userToCreate: IUser = {
          ...payload,
          lessonPermissions: [],
          id: userID,
        };

        commit("CREATE_USER", userToCreate);
      } catch (e) {
        console.error(e);
        if (e.isAxiosError) {
          error =
            e.response?.data?.error ||
            "Что-то пошло не так. Попробуйте еще раз.";
        }
      }

      return error;
    },
  },
  getters: {
    allUsers: (state) => state.users,
    lessonPermissions: (state) => state.lessonPermissions,
  },

  mutations: {
    SET_USER_LESSON_PERMISSIONS(state, payload: IEditLessonPermissions) {
      const { users } = state;
      const userIndex = users.findIndex(
        ({ id }: IUser) => id === payload.userID
      );

      if (userIndex !== -1) {
        const newUser: IUser = {
          ...users[userIndex],
          lessonPermissions: payload.lessonPermissions,
        };

        const newUsers = [...users];

        newUsers[userIndex] = { ...newUser };
        state.users = newUsers;
      }
    },
    SET_ALL_LESSON_PERMISSIONS(state, payload: IAllLessonsPermissions) {
      state.lessonPermissions = payload;
    },
    SET_ALL_USERS(state, users: IUser[]): void {
      state.users = users;
    },
    CREATE_USER(state, user: IUser): void {
      state.users = [...state.users, user];
    },
    EDIT_PERMISSIONS(
      state: typeof usersState,
      payload: IEditUserPermissions
    ): void {
      const { users } = state;
      const user = users.find(({ id }) => id === payload.userID);

      if (user) {
        const newUser: IUser = { ...user, ...payload };
        const userIdx = users.findIndex(({ id }) => id === payload.userID);
        const newUsers = [...users];
        newUsers[userIdx] = { ...newUser };
        state.users = newUsers;
      }
    },
  },
};

export default usersModule;
