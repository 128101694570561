import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=ed2b9de0&scoped=true&"
import script from "./TheHeader.vue?vue&type=script&lang=ts&"
export * from "./TheHeader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed2b9de0",
  null
  
)

export default component.exports