
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class TheHeader extends Vue {
  private get links() {
    return [
      { title: "Главная", to: "/dashboard", condition: true },
      { title: "Курсы", to: "/courses", condition: true },
      {
        title: "Аналитика",
        to: "/analytics",
        condition: this.canReadAnalytics,
      },
    ];
  }

  private get dropdownItems() {
    return [
      {
        to: "/management",
        title: "Управление доступом",
        condition: this.canManageUsers,
      },
    ];
  }

  private get email() {
    return this.$store.getters.userEmail;
  }

  private get canReadAnalytics() {
    return this.$store.getters.canReadAnalytics;
  }

  private get canManageCourses() {
    return this.$store.getters.canManageCourses;
  }

  private get canManageUsers() {
    return this.$store.getters.canManageUsers;
  }

  private async logout() {
    await this.$store.dispatch("logout");
    await this.$router.push("/");
  }
}
