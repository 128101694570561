import axios from "axios";
import router from "@/router";
import store from "@/store";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL_BASE,
  headers: {
    "Content-Type": "application/json",
  },
});
httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch("logout");
      if (router.currentRoute.path !== "/") {
        router.push("/");
      }
    }
    return Promise.reject(error);
  }
);
export default httpClient;
