import { Module } from "vuex";

function getDefaultState(): {
  filePromises: string[];
} {
  return {
    filePromises: [],
  };
}

const fileLoadState = getDefaultState();
const fileLoadModule: Module<any, any> = {
  namespaced: true,
  state: fileLoadState,
  actions: {
    addFile({ commit }, uuid: string) {
      commit("addFile", uuid);
    },
    removeFile({ commit }, uuid: string) {
      commit("removeFile", uuid);
    },
    removeAll({ commit }) {
      commit("removeAll");
    },
  },
  getters: {
    areFilesLoading: (state) => state.filePromises.length > 0,
  },

  mutations: {
    addFile(state, uuid) {
      state.filePromises = [...state.filePromises, uuid];
    },
    removeFile(state, uuid) {
      state.filePromises = state.filePromises.filter(
        (el: string) => el !== uuid
      );
    },
    removeAll(state) {
      state.filePromises = [];
    },
  },
};

export default fileLoadModule;
