import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VToolbar,[_c(VToolbarItems,[_vm._l((_vm.links),function(item,idx){return [(item.condition)?_c(VBtn,{key:idx,attrs:{"to":item.to,"elevation":"0"}},[_vm._v(_vm._s(item.title))]):_vm._e()]})],2),_c(VSpacer),_c(VToolbarItems,[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(_vm.email)+" ")],1)]}}])},[_c(VList,[_vm._l((_vm.dropdownItems),function(item,index){return [(item.condition)?_c(VListItem,{key:index},[_c(VBtn,{attrs:{"text":"","block":"","to":item.to}},[_vm._v(" "+_vm._s(item.title)+" ")])],1):_vm._e()]}),_c(VListItem,[_c(VBtn,{attrs:{"text":"","block":""},on:{"click":_vm.logout}},[_vm._v(" Выйти ")])],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }