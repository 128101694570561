import Vue from "vue";
import Vuex from "vuex";
import { IRootState } from "@/types/root-store";
import users from "@/store/users-module";
import VuexPersistence from "vuex-persist";
import root from "@/store/root-module";
import fileLoadModule from "@/store/file-load-module";

const vuexLocal = new VuexPersistence<IRootState>({
  storage: window.localStorage,
  modules: ["root"],
});

Vue.use(Vuex);

export default new Vuex.Store({
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    users,
    root,
    fileLoadModule,
  },
  plugins: [vuexLocal.plugin],
});
