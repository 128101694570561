
import { Component, Vue } from "vue-property-decorator";
import EmptyLayout from "@/layouts/empty-layout.vue";
import DefaultLayout from "@/layouts/default-layout.vue";
import httpClient from "@/api/http-client";

@Component({
  components: { EmptyLayout, DefaultLayout },
})
export default class App extends Vue {
  private get layout(): string {
    return this.$route.path === "/" ? "EmptyLayout" : "DefaultLayout";
  }

  mounted() {
    const token = this.$cookies.get("jwt");

    if (token) {
      httpClient.defaults.headers.common["Token"] = `${token}`;
    }
  }
}
