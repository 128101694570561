
import { Component, Ref, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {},
})
export default class Home extends Vue {
  @Ref("form") private readonly form!: InstanceType<typeof ValidationObserver>;

  private requiredRule = (val: string): boolean | string =>
    !!val || "Это поле обязательно для заполнения";
  private username = "";
  private password = "";
  private loading = false;
  private loginError = false;

  async onButtonClick(): Promise<void> {
    const isValid = this.form.validate();

    if (!isValid) {
      return;
    }

    const { username, password } = this;

    this.loading = true;

    try {
      await this.$store.dispatch("login", { username, password });
      this.loginError = false;
      await this.$router.push("/dashboard");
    } catch (e) {
      console.error(e);
      this.loginError = true;
    } finally {
      this.loading = false;
    }
  }
}
