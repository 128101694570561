import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/management",
    component: () => import("../views/UserManagement.vue"),
    meta: {
      ruleToHide: "manageUsers",
    },
  },
  {
    path: "/courses",
    component: () => import("../views/Courses.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/courses/:courseId",
    component: () => import("../views/Lessons.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/courses/:courseId/lessons/:lessonId",
    component: () => import("../views/LessonPage.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/courses/:courseId/lessons-create/:lessonId",
    component: () => import("../views/CreateLesson.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/courses/:courseId/lessons-create",
    component: () => import("../views/CreateLesson.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/courses-create/:id",
    component: () => import("../views/CreateCourse.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/courses-create",
    component: () => import("../views/CreateCourse.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/analytics",
    component: () => import("../views/AnalyticPage.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/analytics/:userId",
    component: () => import("../views/UserAnalyticPage.vue"),
    meta: {
      requireAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let { token } = store.getters;
  const { userPermissions } = store.getters;

  if (!token) {
    token = Vue.$cookies.get("jwt");
  }

  const { meta } = to;

  if (meta && Object.keys(meta).length !== 0) {
    if (token) {
      if (!userPermissions) {
        next("/dashboard");
        return;
      }

      const { ruleToHide } = meta;
      if (!ruleToHide) {
        next();
        return;
      }

      if (userPermissions[ruleToHide]) {
        next();
        return;
      }

      next("/dashboard");
      return;
    } else {
      next("/");
      return;
    }
  }

  if (token && to.path === "/") {
    next("/dashboard");
    return;
  }

  next();
});
export default router;
